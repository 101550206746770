<template>
  <div id="order-view">
    <div v-if="!isLoading">
      <div class="row">
        <div class="col-12 no-top-padding">
            <b-tabs nav-class="nav-tabs nav-bordered">
                <b-tab title="Overview" :active="activeTab === 0">
                  <order-overview />
                </b-tab>
                <b-tab title="Designs" :active="activeTab === 1" :lazy="activeTab != 1">
                  <order-designs />
                </b-tab>
            </b-tabs>
        </div>
      </div>
    </div>
    <is-loading v-else/>
  </div>
</template>

<script>

import IsLoading from "@/components/IsLoading.vue"
import OrderOverview from "@/components/order/OrderOverview.vue"
import OrderDesigns from "@/components/order/OrderDesigns.vue"

export default {
  components: {
    OrderOverview,
    OrderDesigns,
    IsLoading
  },
  data() {
    return {
      isLoading: true,
    }
  },
  watch: {
    "$route":"fetchOrder"
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    activeTab(){
      return this.$route.query.tab ? +this.$route.query.tab : 0;
    },
  },
  methods:{
    fetchOrder(){
      this.isLoading = true
      this.$store.dispatch("orderList/fetchOrder",this.$route.params.orderId)
      .then((response) => {
         this.isLoading = false
         if(response.data.success){
            this.updateBreadcrumb()
          }else{
            this.$router.push({path: '/error-404'})
          }
      })
    },
    updateBreadcrumb(){
        let breadcrumb = [
            {
                text: `${this.order.status.charAt(0).toUpperCase() + this.order.status.slice(1) } Orders`,
                to: { path: `/orders/?status=${this.order.status}` }
            },
            {
                text: "Show",
                active: true
            }
        ]
        this.$route.meta.breadcrumb = [...breadcrumb]
    },
  },
  created(){
    this.fetchOrder();
  },
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
.no-top-padding .tab-content{
    padding-top:1px !important;
}
</style>
